import { createFormActions, createInput } from ":shared/components/form";
import { auth } from ":shared/helpers/validations";
import { global } from ":global";

const is_dev = global.helper.env.isDev;
const mocks = {
  eslam_temp_participant: {
    email: "eslam-temp-participant@jcnorris.com",
    password: "Designathon@321",
  },
  eslam_temp_part: {
    email: "eslam-temp-part@mitico.org",
    password: "Designathon@321",
  },
  eslam_temp_support: {
    email: "eslam-temp-support@jcnorris.com",
    password: "Designathon@321",
  },
  eslam_temp_eval: {
    email: "eslam-temp-eval@jcnorris.com",
    password: "Designathon@321",
  },
  eslam_temp_auditor: {
    email: "eslam-temp-auditor@jcnorris.com",
    password: "Designathon@321",
  },
  student: {
    email: "abdul.2020alsh@gmail.com",
    password: "Test@332211",
  },
  // eslam on mail.tm, password same as email on mail.tm and moc
  eslam_temp_admin: {
    email: "eslam-temp-admin@hldrive.com",
    password: "Moc@1234",
  },
  eslam_temp_student: {
    email: "eslam-temp-student@hldrive.com",
    password: "Moc@1234",
  },
  eslam_temp_tutor: {
    email: "eslam-temp-tutor@hldrive.com",
    password: "Moc@1234",
    //
  },
};

export function createLoginInputs(mock?: keyof typeof mocks) {
  const m = is_dev && mock ? mocks[mock] : undefined;
  const Email = createInput(m?.email, {
    id: "Email",
    required: true,
    validators: (value) => {
      const err = auth.email.validate(value);
      // return { "wow what a very loong user error": true };
      return err?.length > 0 ? { [err]: true } : undefined;
    },
  });
  const Password = createInput(m?.password, {
    id: "Password",
    required: true,
    validators: (value) => {
      const err = auth.password.validate(value);
      // console.log("validating password", value, " :: ", err);
      return err?.length > 0 ? { [err]: true } : undefined;
    },
  });
  const Actions = createFormActions({ Email, Password });
  return {
    Actions,
    Email,
    Password,
  };
}
